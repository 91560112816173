<template>
    <spin-loader :showMe="loading" />
    <dropdown v-show="!loading" v-model="dropDownState" type="kebab">
        <template #body>
            <spin-loader :showMe="loading" />
            <menu-vertical v-show="!loading" options="panel options medium">
                <menu-item v-if="isSearchOptions && !isCardOptions" :label="'Search Options'" :initIsOpen="true">
                    <template #children>
                        <menu-item v-if="flagItem" @click.prevent="share" :label="'Share Search...'" />
                        <menu-item label="Save As New Card..." @click.prevent="openModal('saveSearchResultsModal')"> </menu-item>
                        <menu-item v-if="queryListID" label="List Actions">
                            <section class="margin-bottom-xsmall">
                                <label style="font-size:100%">This Search has a List associated with it.</label>
                            </section>
                            <section class="margin-bottom-xsmall">
                                <button @click.prevent="openList(queryListID, querySubsetID)" type="button" class="button-primary margin-right-xsmall">List Details</button>
                                <button @click.prevent="mailToList(queryListID, querySubsetID)" type="button" class="button-primary">Set up List Mailing</button>
                            </section>
                        </menu-item>
                    </template>
                </menu-item>
                <menu-item v-if="isCardOptions" :label="'Card Options'" :initIsOpen="true">
                    <template #children>
                        <menu-item v-if="!isSearchOptions && dateRangeDesc" :label="dateRangeDesc" icon="calendar" />
                        <menu-item v-if="flagItem" @click.prevent="share" :label="'Share Card...'" />
                        <menu-item v-if="queryChanges && Object.keys(queryChanges).length > 0 && userIsOwner" label="Save Changes..." @click.prevent="openModal('previewSaveQueryChangesModal')"></menu-item>
                        <menu-item v-if="query || (model && model.query)" :label="isCardOptions && !isSearchOptions ? 'Copy Card...' : 'Save As New Card...'" @click.prevent="openModal('saveSearchResultsModal')"></menu-item>
                        <menu-item v-if="!loading && userIsOwner" label="Delete Card" @click.prevent="deleteCard" />
                        <menu-item v-if="userIsOwner" label="Rename Card">
                            <form class="form-attributes-edit">
                                <div class="input-group nowrap">
                                    <div class="input-wrapper">
                                        <div class="input-field fullwidth">
                                            <input v-model="model.name" class="style-alternate" type="text" autocomplete="new-password">
                                        </div>
                                    </div>
                                    <spin-loader :showMe="loading" />
                                    <btn v-if="!loading" el="button" title="Save" @click.prevent="rename" type="primary">Save</btn>
                                </div>
                            </form>
                        </menu-item>
                        <menu-item v-if="userIsOwner && isQueryCard" label="Modify Card">
                            <form class="form-attributes-edit" autocomplete="off">
                                <fieldset class="checkbox margin-bottom-small">
                                    <input v-model="model.fullScreen" name="fullScreen" :id="model.id + '-fullScreen'" type="checkbox">
                                    <label :for="model.id + '-fullScreen'">Full Width card</label>
                                </fieldset>
                                <section class="cardDisplay margin-bottom-small">
                                    <fieldset>
                                        <label>Card Type</label>
                                        <select name="cardDisplayType" class="form-control fullwidth" v-model="model.cardDisplayType">
                                            <option v-for="(displayType, key) in cardDisplayTypes" v-bind:key="key" :value="key">{{ displayType }}</option>
                                        </select>
                                    </fieldset>
                                    <fieldset v-if="model.cardDisplayType == 1">
                                        <label>Aggregate Panel</label>
                                        <select name="aggregatePanel" class="form-control fullwidth" v-model="model.aggregatePanel">
                                            <option v-for="(aggPanel, key) in aggregatePanels" v-bind:key="key" :value="aggPanel">{{ aggPanel}}</option>
                                        </select>
                                    </fieldset>
                                    <fieldset v-if="model.cardDisplayType == 5">
                                        <label>Aggregate Name</label>
                                        <select name="graphAggregate" class="form-control fullwidth" v-model="model.graphAggregate">
                                            <option v-for="(graphAgg, key) in graphAggregates" v-bind:key="key" :value="graphAgg">{{ graphAgg }}</option>
                                        </select>
                                    </fieldset>
                                    <fieldset v-if="model.cardDisplayType == 6">
                                        <label>Tag Cloud Name</label>
                                        <select name="tagCloudName" class="form-control fullwidth" v-model="model.tagCloudName">
                                            <option v-for="(tagCloud, key) in tagClouds" v-bind:key="key" :value="tagCloud">{{ tagCloud }}</option>
                                        </select>
                                    </fieldset>
                                    <fieldset v-if="model.cardDisplayType == 2 ||model.cardDisplayType == 3 || model.cardDisplayType == 4">
                                        <label>Entries shown</label>
                                        <input class="form-control style-alternate fullwidth" required="" name="limit" type="number" v-model="model.limit" autocomplete="new-password">
                                    </fieldset>
                                </section>
                                <section class="cardDate margin-bottom-small">
                                    <fieldset>
                                        <div class="checkbox">
                                            <input v-model="model.lockDateFilter" name="lockDateFilter" :id="model.id + '-lockDateFilter'" type="checkbox">
                                            <label :for="model.id + '-lockDateFilter'">Allow independent Date Range for this card</label>
                                        </div>
                                    </fieldset>
                                    <fieldset v-if="model.lockDateFilter">
                                        <div v-if="queryDateFilterKeys.length">
                                            <label>Date Filter</label>
                                            <select v-model="model.dateFilterName" name="dateFilterName" class="form-control fullwidth">
                                                <option v-for="(key) in queryDateFilterKeys" v-bind:key="key" :value="key">{{ key }}</option>
                                            </select>
                                        </div>
                                        <div v-if="queryDateRangeKeys.length">
                                            <label>Date Range</label>
                                            <select v-model="model.dateRangeKey" name="dateRangeKey" class="form-control fullwidth">
                                                <option v-for="(key) in queryDateRangeKeys" v-bind:key="key" :value="key">{{ dateRanges[key] ? dateRanges[key].description : key  }}</option>
                                            </select>
                                        </div>
                                    </fieldset>
                                </section>
                                <section>
                                    <spin-loader :showMe="loading" />
                                    <button v-if="!loading" @click.prevent="saveOptions" type="button" class="button-primary">Save</button>
                                </section>
                            </form>
                        </menu-item>
                        <menu-item v-if="queryListID" label="List Actions">
                            <section class="margin-bottom-xsmall">
                                <label style="font-size:100%">This Card has a List associated with it.</label>
                            </section>
                            <section class="margin-bottom-xsmall">
                                <button @click.prevent="openList(queryListID, querySubsetID)" type="button" class="button-primary margin-right-xsmall">List Details</button>
                                <button @click.prevent="mailToList(queryListID, querySubsetID)" type="button" class="button-primary">Set up List Mailing</button>
                            </section>
                        </menu-item>
                    </template>
                </menu-item>
                <menu-item v-if="isSearchOptions" label="Advanced Options">
                    <template #children>
                        <menu-item v-if="newList.listID" label="View recent List..." @click.prevent="openModal('listCreatedModal')"></menu-item>
                        <menu-item v-if="canSaveToList" label="Save Search to List..." @click.prevent="openModal('saveToListModal')"></menu-item>
                        <!--<menu-item label="Save Search to existing Card..." @click.prevent="openModal('saveToCardModal')"></menu-item>-->
                        <menu-item label="Download Search to CSV" @click.prevent="openModal('downloadToCSVModal')"></menu-item>
                        <menu-item label="Open in old Search Page" @click.prevent="openOldSearch" />
                    </template>
                </menu-item>
                <menu-item label="Move" v-if="showMove">
                    <template #children>
                        <spin-loader :showMe="loading" />
                        <template v-if="model.idx > 0 && !loading">
                            <menu-item label="To Front" icon="angle-double-left" @click="move(0)" />
                            <menu-item label="Forward" icon="angle-left" @click="move(model.idx - 1)" />
                        </template>
                        <template v-if="dashboard && model.idx != dashboard.cards.length - 1 && !loading">
                            <menu-item label="Backward" icon="angle-right" @click="move(model.idx + 1)" />
                            <menu-item label="To Back" icon="angle-double-right" @click="move(dashboard.cards.length - 1)" />
                        </template>
                        <menu-item v-if="!loading && showMove" @click.prevent="openModal('moveToModal')" label="Move to..." />
                    </template>
                </menu-item>
                <menu-item v-if="criteria && !isSearchOptions" label="View Filters">
                    <template #children>
                        <applied-filters :criteria="criteria" :advanced="true" :readOnly="true" />
                    </template>
                </menu-item>
            </menu-vertical>
        </template>
    </dropdown>

    <query-changes-modal v-model="modalState['previewSaveQueryChangesModal']"
                         title="Save Changes"
                         :card="model"
                         :queryChanges="queryChanges"
                         @save="saveOptions" />

    <dashboard-select-modal v-model="modalState['moveToModal']"
                            action="Move"
                            title="Move to Dashboard"
                            :card="model"
                            :showSnapshotCheck="false" />

    <dashboard-select-modal v-model="modalState['saveSearchResultsModal']"
                            :action="isCardOptions && !isSearchOptions ? 'Copy' : 'Save'"
                            :actionLabel="isCardOptions && !isSearchOptions ? 'Copy' : 'Save'"
                            :actionToLabel="isCardOptions && !isSearchOptions ? 'to' : 'in'"
                            :title="isCardOptions && !isSearchOptions ? 'Copy Card' : 'Save As New Card'"
                            :card="model"
                            :obj="query ? query : model.query"
                            :showCardCreate="true"
                            :showSnapshotCheck="true"
                            :initialNewCardName="isCardOptions && model && model.name ? 'Copy of ' + model.name : ''"
                            :initialNewCardType="searchDisplayType ? searchDisplayType : (model ? model.cardDisplayType : '')"
                            :cardDateRangeKeys="queryDateRangeKeys"
                            :openNewCardInNewTabOverride="isSearchOptions" />

    <save-to-list-modal v-model="modalState['saveToListModal']"
                        title="Save to List"
                        :card="model"
                        :primaryContactsToggle="saveToListPrimaryContactsToggle"
                        @save="saveToList" />

    <dashboard-select-modal v-model="modalState['saveToCardModal']"
                            :showDashCreate="false"
                            :showCardSelect="true"
                            :showSnapshotCheck="true"
                            cardFilter="list"
                            :actionToLabel="''"
                            title="Save to Existing Card"
                            @card-selected="cardSaveToList" />

    <modal v-model="modalState['listCreatedModal']" title="List Actions">
        <template #body>
            <template v-if="newList && newList.listID">
                <section class="box options">
                    <section class="margin-bottom-small">{{ newList.listName }}</section>
                    <button v-show="newList.listID" @click.prevent="openList(newList.listID,newList.subsetID)" type="button" class="button-primary margin-right-xsmall">List Details</button>
                    <button v-show="newList.listID" @click.prevent="openListSearch(newList.listID, newList.subsetID)" type="button" class="button-primary margin-right-xsmall">Open List in Search</button>
                    <button v-show="newList.listID" @click.prevent="mailToList(newList.listID, newList.subsetID)" type="button" class="button-primary">Set up List Mailing</button>
                </section>
                <section>&nbsp;</section>
            </template>
        </template>
    </modal>
    <modal v-model="modalState['downloadToCSVModal']" title="Download to CSV" :showOverflow="true">
        <template #body>
            <button @click.prevent="downloadCsv(true)" type="button" class="button-primary margin-right-xsmall">Custom Schema</button>
            <button @click.prevent="downloadCsv(false)" type="button" class="button-primary margin-right-xsmall">Default Schema</button>
        </template>
    </modal>
</template>

    <script>
        import { ref, computed, onMounted, inject } from 'vue'
        import { useStore } from 'vuex'
        import { useToast } from '../../composables/ModelWrapper.js'

        import _ from 'lodash'

        import MenuVertical from "../ui/menus/MenuVertical.vue"
        import MenuItem from "../ui/menus/MenuItem.vue"

        import AppliedFilters from '../Search/AppliedFilters.vue'
        import DashboardSelectModal from '../Modals/DashboardSelectModal.vue'
        import QueryChangesModal from '../Modals/QueryChangesModal.vue'
        import SaveToListModal from '../Modals/SaveToListModal.vue'

        export default {
            name: 'CardOptions',
            components: {
                MenuVertical, MenuItem, AppliedFilters, DashboardSelectModal, QueryChangesModal, SaveToListModal
            },
            emits: ['saved'],
            props: ['dashboard', 'card', 'searchResultsSet', 'searchDisplayType', 'query', 'queryChanges'],
            setup(props, { emit }) {
                const store = useStore()
                const toast = useToast()
                const nextAlert = inject('nextAlert')
                const nextConfirm = inject('nextConfirm')
                const flagShare = inject("flagShare")

                // data
                const modalState = ref({ })
                const dropDownState = ref('closed')
                const model = ref(_.clone(props.card))
                const dashboardsShown = ref(5)
                const loading = ref(false)

                const newList = ref({})

                // computed
                const user = computed(() => store.state.userInfo.user.userInfo)
                const dateRanges = computed(() => store.getters["search/dateRangeMap"])
                const dateFilters = computed(() => store.getters["search/dateFilters"])
                const cardDisplayTypes = computed(() => store.getters["search/cardDisplayTypes"])
                const aggregatePanels = computed(() => store.getters["search/aggPanels"])
                const graphAggregates = computed(() => store.getters["search/graphAggs"])
                const tagClouds = computed(() => store.getters["search/significantTermAggs"])

                const isCardOptions = computed(() => !!props.card)
                const isSearchOptions = computed(() => !!props.query)
                const flagItem = computed(() => {
                    if (isCardOptions.value) return props.card
                    if (isSearchOptions.value) return props.query
                    return null
                })
                const esCode = computed(() => props.searchResultsSet && props.searchResultsSet.query ? props.searchResultsSet.query.detailAddress : null)
                const dateRangeDesc = computed(() => isCardOptions.value && model.value.dateRangeKey ? (dateRanges.value[model.value.dateRangeKey] ? dateRanges.value[model.value.dateRangeKey].description : 'Custom Range') : null)
                const userIsOwner = computed(() => props.card && user.value ? user.value.id == model.value.entryUserID : false)
                const userIsDashOwner = computed(() => props.dashboard && user.value ? user.value.id == props.dashboard.entryUserID : false)
                const isQueryCard = computed(() => isCardOptions.value && model.value.sourceObjectType == 'query')
                const showMove = computed(() => isCardOptions.value && !isSearchOptions.value && props.dashboard && props.dashboard.cards && userIsDashOwner.value)

                const criteria = computed(() => isQueryCard.value && props.card && props.card.query ? props.card.query.criteria : (props.query ? props.query.criteria : null))

                const queryDateFilterKeys = computed(() => criteria.value && dateFilters.value ? _.filter(dateFilters.value, function (f) { return criteria.value[f] }) : [])
                const queryDateFilters = computed(() => criteria.value ? _.map(_.filter(criteria.value, function (crit, key) { return queryDateFilterKeys.value.includes(key) }), function (filter) { return JSON.parse(filter.value) }) :[])
                const queryDateRangeKeys = computed(() => [...new Set(_.map(queryDateFilters.value, function (filter) { return filter ? filter.dateRangeKey : 'AllTime' }) )])
                const hasOwnDateRange = computed(() => props.dashboard && props.dashboard.dateRangeKey ? !queryDateRangeKeys.value.includes(props.dashboard.dateRangeKey) : false);

                const hasQueryChanges = computed(() => props.queryChanges ? Object.keys(props.queryChanges).length > 0 : false)
                const hasQueryDateRangeChanges = computed(() => props.queryChanges && dateFilters.value && _.find(dateFilters.value, function (f) { return props.queryChanges[f] }) ? true : false)

                const queryListID = computed(() => {
                    if (criteria.value) {
                        if (criteria.value["lists"] && criteria.value["lists"].value) {
                            var mspList = JSON.parse(criteria.value["lists"].value);
                            if (mspList.listSets && mspList.listSets.length == 1 && mspList.listSets[0])
                                return mspList.listSets[0].id;
                        }
                        if (criteria.value["listID"] && criteria.value["listID"].value) {
                            return criteria.value["listID"].value;
                        }
                    }
                    else
                        return null;
                })
                const querySubsetID = computed(() => {
                    if (criteria.value) {
                        if (criteria.value["lists"] && criteria.value["lists"].value) {
                            var mspList = JSON.parse(criteria.value["lists"].value);
                            if (mspList.listSets && mspList.listSets.length == 1 && mspList.listSets[0] && mspList.listSets[0].subsetIDs)
                                return mspList.listSets[0].subsetIDs[0];
                        }
                        if (criteria.value["subsetID"] && criteria.value["subsetID"].value) {
                            return criteria.value["subsetID"].value;
                        }
                    }
                    else
                        return null;
                })
                const canSaveToList = computed(() => {
                    if (criteria.value && criteria.value.docType && criteria.value.docType.valueName) {
                        if (['Company', 'Acquisition', 'Person'].indexOf(criteria.value.docType.valueName) > -1) return true;
                    }
                    return false;
                })
                const saveToListPrimaryContactsToggle = computed(() => {
                    if (criteria.value && criteria.value.docType && criteria.value.docType.valueName) {
                        if (['Company'].indexOf(criteria.value.docType.valueName) > -1) return true;
                    }
                    return false;
                })

                // methods
                const openModal = (name) => {
                    dashboardsShown.value = 5
                    dropDownState.value = 'closed'
                    modalState.value[name] = true
                }

                const cardSaveToList = (opt) => {
                    if (!opt)
                        opt = {};

                    var newOpt = {
                        cardID: opt.id,
                        dashboardID: opt.ownerID,
                        listID: opt.filterID,
                        deepSave: opt.deepSave
                    }
                    saveToList(newOpt)
                }

                const openOldSearch = () => {
                    var query = props.query ? props.query : (props.card && props.card.query ? props.card.query : null);
                    if (query && query.id) {
                        window.open('https://cmdm.berkerynoyes.com/Search?queryID=' + query.id + '&ignoreRedirect=true');
                    }
                    else
                        window.open('https://cmdm.berkerynoyes.com/Search?docType=Company&ignoreRedirect=true');
                }

                const saveToList = (opt) => {
                    if (opt && (opt.listID || opt.listName)) {
                        var query = props.query ? props.query : (props.card && props.card.query ? props.card.query : null);
                        if (query) {
                            loading.value = true;
                            store.dispatch('search/createSearchSnapshot', {
                                listID: opt ? opt.listID : 0,
                                listName: opt ? opt.listName : "",
                                subsetID: opt ? opt.subsetID : 0,
                                subsetName: opt ? opt.subsetName : "",
                                query: query,
                                deepSave: opt ? opt.deepSave : false,
                                autoPop:  opt ? opt.autoPop : false
                            }).then((res) => {
                                loading.value = false;
                                if (opt.cardID && opt.dashboardID) {
                                    toast.add({ severity: 'success', summary: 'Search Results added to Card', detail: "", life: 5000 })
                                    window.open('/Search?cardid=' + opt.cardID + '&dashboardid=' + opt.dashboardID);
                                }
                                else {
                                    toast.add({ severity: 'success', summary: 'Search Results added to List', detail: "", life: 5000 })
                                    //open new list modal 
                                    var list = JSON.parse(res.jsonResult);
                                    if (list) {
                                        newList.value = {
                                            listID: list.id,
                                            listName: list.name,
                                            subsetID: (opt.subsetID || opt.subsetName) && list.subsets[0] ? list.subsets[0].id : 0,
                                            subsetName: (opt.subsetID || opt.subsetName) && list.subsets[0] ? list.subsets[0].name : ""
                                        };
                                        openModal('listCreatedModal')
                                    }
                                }

                            }).catch(() => {
                                loading.value = false;
                                toast.add({ severity: 'error', summary: 'Could not add Search Results to '+ (opt.cardID ? 'Card' : 'List'), detail: "", life: 5000 })
                            });
                        }
                    }
                }
                const share = () => {
                    var itemToShare = model.value ? model.value : props.query
                    console.log('share', itemToShare, model.value, props.query)
                    if (itemToShare) {
                        flagShare(itemToShare).then((res) => { console.log('shareCompleted', res) }).catch((err) => { console.log('shareError', err) })
                    }
                }
                const rename = () => {
                    loading.value = true
                    model.value.dontFetchData = true
                    store.dispatch('dashboards/updateCard', model.value).then(() => {
                        loading.value = false
                        dropDownState.value = 'closed'
                        toast.add({ severity: 'success', summary: 'Renamed Card', detail: 'Renamed to ' + model.value.name, life: 2000 })
                    }).catch(error => {
                        loading.value = false
                        toast.add({ severity: 'error', summary: 'Error renaming Card', detail: error, life: 3000 })
                    })
                }
                const saveOptions = () => {
                    loading.value = true
                    if (isSearchOptions.value) {
                        model.value.dontFetchData = true
                        model.value.query = props.query
                        model.value.lockDateFilter = model.value.lockDateFilter ? model.value.lockDateFilter : (hasQueryDateRangeChanges.value && hasOwnDateRange.value)
                    }

                    store.dispatch('dashboards/updateCard', model.value).then(() => {
                        loading.value = false
                        emit('saved')
                        dropDownState.value = 'closed'
                        toast.add({ severity: 'success', summary: 'Saved Card', detail: 'Card changes saved', life: 2000 })
                    }).catch(error => {
                        loading.value = false
                        toast.add({ severity: 'error', summary: 'Error saving Card', detail: error, life: 3000 })
                    })
                }
                const deleteCard = () => {
                    nextConfirm('Delete card?').then((res) => {
                        if (res) {
                            loading.value = true
                            store.dispatch('dashboards/deleteCard', {
                                'id': model.value.id,
                                'ownerID': model.value.ownerID
                            }).then(() => {
                                loading.value = false;
                                dropDownState.value = 'closed'
                                toast.add({ severity: 'success', summary: 'Deleted Card', detail: 'Card Deleted', life: 3000 })
                            }).catch((error) => {
                                loading.value = false;
                                toast.add({ severity: 'error', summary: 'Error Deleting Card', detail: error, life: 3000 })
                            })
                        }
                    })
                }
                const move = (idx) => {
                    loading.value = true;
                    store.dispatch('dashboards/moveCard', {
                        'id': model.value.id,
                        'ownerID': model.value.ownerID,
                        'index': idx
                    }).then(() => {
                        loading.value = false;
                        dropDownState.value = 'closed'
                        toast.add({ severity: 'success', summary: 'Moved Card', detail: 'Moved to ' + idx, life: 3000 })
                    }).catch((error) => {
                        loading.value = false
                        toast.add({ severity: 'error', summary: 'Error Moving Card', detail: error, life: 3000 })
                    })
                }
                const downloadCsv = (custom) => {
                    var req = null;
                    var query = props.query ? props.query : (props.card && props.card.query ? props.card.query : null );
                    if (query) {
                        var crit = query.criteria;
                        var qresults = query.total;
                        if (crit.docType && crit.docType.value) {
                            req = { sourceID: query.id, exportSourceType: 3, exportschemaID: 0 };
                            if (crit.docType.value.indexOf('Company') > -1)
                                req.sourceType = 'companyobject';
                            else if (crit.docType.value.indexOf('Acquisition') > -1)
                                req.sourceType = 'acquisitionobject';
                            else if (crit.docType.value.indexOf('Person') > -1)
                                req.sourceType = 'personobject';
                            else
                                req = null;
                        }

                        if (req != null) {
                            req.total = qresults;
                            req.text = crit.fulltext ? crit.fulltext.valueName : "";
                            var schemaMap = store.state.search.searchMaps.maps.exportSchemas;
                            req.exportschemaID = schemaMap[req.sourceType];

                            if (custom)
                                window.open('https://cmdm.berkerynoyes.com/Export/CustomExport?sourceID=' + req.sourceID + '&sourceType=' + req.sourceType + '&exportSourceType=' + req.exportSourceType + '&sourceResults=' + req.total + '&sourceQuery=' + req.text);
                            else
                                window.open('https://cmdm.berkerynoyes.com/Export/ExportList?sourceID=' + req.sourceID + '&exportSourceType=' + req.exportSourceType + '&exportschemaID=' + req.exportschemaID);

                            dropDownState.value = 'closed'
                            modalState.value["downloadToCSVModal"] = false
                            return;
                        }
                    }
                    nextAlert('Unable to Export this Search.');
                }
                const openList = (listID,subsetID) => {
                    window.open('https://cmdm.berkerynoyes.com/List/Details/' + listID + (subsetID ? '?subsetID=' + subsetID : ''));
                }
                const openListSearch = (listID, subsetID) => {
                    window.open('https://cmdm-beta.berkerynoyes.com/Search?docType=Company&listID=' + listID + (subsetID ? '&subsetID=' + subsetID : ''));
                }
                const mailToList = (listID, subsetID) => {
                    window.open('https://cmdm.berkerynoyes.com/letterwizard/Blast?listTypeID=12&listID=' + listID + (subsetID ? '&subsetID=' + subsetID : ''));
                }
                const syncList = (listID, subsetID) => {
                    loading.value = true;
                    store.dispatch('lists/syncList', { 'listID': listID, 'subsetID' :  subsetID }).then(() => {
                        loading.value = false;
                        dropDownState.value = 'closed'
                        toast.add({ severity: 'success', summary: 'List Syncd', detail: '', life: 3000 })
                        window.location.reload()
                    }).catch(() => {
                        loading.value = false
                        toast.add({ severity: 'error', summary: 'Error Syncing List', detail: '', life: 3000 })
                    })
                }

                onMounted(() => {
                    modalState.value = { }
                    dropDownState.value = 'closed'
                    model.value = _.clone(props.card)
                    dashboardsShown.value = 5
                    loading.value = false
                    newList.value = {}
                })

                return {
                    // data
                    modalState, model, dropDownState, dashboardsShown, loading, newList,
                    // computed
                    user, dateRanges, dateFilters, cardDisplayTypes, aggregatePanels, graphAggregates, tagClouds,
                    isCardOptions, isSearchOptions, flagItem, esCode, dateRangeDesc, userIsOwner, isQueryCard, showMove,
                    hasQueryChanges, hasQueryDateRangeChanges,
                    criteria, hasOwnDateRange, queryDateFilterKeys, queryDateRangeKeys, queryListID, querySubsetID, canSaveToList, saveToListPrimaryContactsToggle,
                    // methods
                    share, rename, openModal, saveOptions, deleteCard, move, downloadCsv, nextAlert, nextConfirm, flagShare, saveToList, cardSaveToList, syncList, openList, openListSearch, mailToList, openOldSearch
                }
            },
            watch: {
                card() {
                    this.model = _.cloneDeep(this.card)
                },
                'model.cardDisplayType'(newVal) {
                    if (newVal == 2)
                        this.model.limit = 5;
                    else if (newVal == 3)
                        this.model.limit = 8;
                    else if (newVal == 4)
                        this.model.limit = 3;
                },
            }
        };
    </script>

    <style scoped lang="scss">
    </style>
