<template>
    <ul v-if="tagData && tagData.length" class="record-details">
        <template v-for="(tag, tidx) in tagData" :key="tidx">
            <tag-snapshot v-if="!hideSnapshot && tag.subTags"
                          :name="tag.name"
                          :type="tag.type ?? 'default'"
                          :title="tag.title"
                          :count="tag.count"
                          :actions="tag.actions"
                          :subTags="tag.subTags"
                          :class="(tag.class ? tag.class + ' ' : '') + ' margin-right-min horizontal'"
                          @button-clicked="handleClick(tag.name, $event)"
                          @subtag-clicked="handleClick(tag.name, $event.action, $event.tag)" />
            <tag-basic v-else-if="!hideBasic && !tag.subTags"
                       :type="tag.type ?? 'keyword'"
                       :name="tag.name"
                       :label="tag.label"
                       :title="tag.title"
                       :icon="tag.icon"
                       :href="tag.href"
                       :count="tag.count"
                       :class="(tag.class ? tag.class + ' ' : '') + 'margin-right-min'"
                       :actions="tag.actions"
                       @button-clicked="handleClick(tag.name, $event)" />

        </template>
    </ul>
</template>

<script>
    import { computed } from 'vue'
    import { getRecordTools } from '../../composables/RecordTools.js'
    import { getTextTools } from '../../composables/TextTools.js'
    import { getSearchTools } from '../../composables/SearchTools.js'
    import { useStore } from 'vuex'

    import TagBasic from '../ui/tags/TagBasic.vue'
    import TagSnapshot from '../ui/tags/TagSnapshot.vue'
    import _ from 'lodash'

    export default {
        name: 'RecordTags',
        components: {
            TagSnapshot, TagBasic
        },
        props: {
            'obj': { type: Object },
            'hideSnapshot': { type: Boolean },
            'hideBasic': { type: Boolean },
            'hideTypes': { type: Array },
            'isFullInfoContext': { type: Boolean }
        },
        setup(props) {
            const store = useStore()
            const { objectType, objectID, objectName, storeKey } = getRecordTools(props)
            const { updateUserName } = getSearchTools()
            const { toShortDateString, formatMillions, toEventDate } = getTextTools() //decorateHighlights

            const lastFinancial = computed(() => {
                var lastFin = props.obj && props.obj.financials && props.obj.financials.length ?
                    _.sortBy(props.obj.financials, function (f) { return f.id * -1 })[0] : //calculate our own lastFin from the financials if we have financials
                    (props.obj && props.obj.lastFinancial ? props.obj.lastFinancial : null); //otherwise look at the obj's lastFinancial

                if (lastFin && lastFin.year > 0 && (lastFin.revenueUSD || lastFin.ebitdaUSD || lastFin.employeeSize))
                    return lastFin;
                else
                    return null
            })

            const comments = computed(() => {
                if (store.state.comments && store.state.comments.commentLog && store.state.comments.commentLog[storeKey.value])
                    return store.state.comments.commentLog[storeKey.value].events
                else
                    return props.obj && props.obj.comments ? props.obj.comments : []
            })

            const balanceSheet = computed(() => props.obj && props.obj.balanceSheet && props.obj.balanceSheet.pricePaidUSD > 0 ? props.obj.balanceSheet : null)
            const sellerFinance = computed(() => props.obj && props.obj.seller && props.obj.seller.finance ? props.obj.seller : null)
            const primaryEmail = computed(() => props.obj && props.obj.primaryEmail && props.obj.primaryEmail.email ? props.obj.primaryEmail.email : null)
            const primaryTel = computed(() => props.obj && props.obj.primaryTel && props.obj.primaryTel.tel ? props.obj.primaryTel.tel : null)

            const tagData = computed(() => {
                var tags = []
                var snapshots = []
                if (props.obj && objectType.value) {
                    switch (objectType.value) {
                        case 'company':
                            if (props.obj.leadAnalysis && props.obj.leadAnalysis != 'Unknown') {
                                tags.push({ 'name': props.obj.leadAnalysis, 'label': 'lead-analysis' })
                            }

                            if (props.obj.mandaInterest != null) {
                                var mandaInterestTags = []
                                if (props.obj.mandaInterest.sellInterest)
                                    mandaInterestTags.push({ 'name': 'Sell' })
                                if (props.obj.mandaInterest.buyInterest)
                                    mandaInterestTags.push({ 'name': 'Buy' })
                                if (props.obj.mandaInterest.divestInterest)
                                    mandaInterestTags.push({ 'name': 'Divest' })
                                if (props.obj.mandaInterest.valueInterest)
                                    mandaInterestTags.push({ 'name': 'Valuation' })
                                if (props.obj.mandaInterest.financeInterest)
                                    mandaInterestTags.push({ 'name': 'Financing' })
                                if (props.obj.mandaInterest.noInterest)
                                    mandaInterestTags.push({ 'name': 'None' })
                                else if (props.obj.mandaInterest.timingID > 0 && props.obj.mandaInterest.timing != 'Unknown') {
                                    mandaInterestTags.push({
                                        'name': props.obj.mandaInterest.timing +
                                            (props.obj.mandaInterest.timingDate ? ' (' + toShortDateString(props.obj.mandaInterest.timingDate) + ')' : '')
                                    })
                                }
                                if (mandaInterestTags.length == 1) {
                                    tags.push({ 'name': mandaInterestTags[0].name, 'label': 'M&A Interest', 'class': 'ma-tag' })
                                }
                                else if (mandaInterestTags.length > 1)
                                    snapshots.push({ 'name': 'M&A Interest', 'class': 'ma-tag', subTags: mandaInterestTags })
                            }

                            if (comments.value.length) {
                                var comment = comments.value.find(c => [0, 8].includes(c.biCategory))
                                if (comment) {
                                    var usr = updateUserName({ id: comment.entryUserID, name: comment.entryUserName })
                                    tags.push({ 'name': usr.name + ' (' + toEventDate(comment.date) + ')', 'class': 'last-contacted', 'icon': 'comments' })
                                }
                            }

                            if (lastFinancial.value) {
                                var finTag = {
                                    name: lastFinancial.value.year,
                                    subTags: []
                                }
                                if (lastFinancial.value.revenueUSD > 0) {
                                    finTag.subTags.push({ 'label': 'Rev', name: formatMillions(lastFinancial.value.revenueUSD) })
                                }
                                if (lastFinancial.value.ebitdaUSD != 0) {
                                    finTag.subTags.push({ 'label': 'Ebitda', name: formatMillions(lastFinancial.value.ebitdaUSD) })
                                }
                                if (lastFinancial.value.employeeSize > 0) {
                                    finTag.subTags.push({ title: 'employees', 'icon': 'user-friends', name: lastFinancial.value.employeeSize.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") })
                                }
                                snapshots.push(finTag)
                            }
                            break
                        case 'acquisition':
                            tags.push({ label: props.obj.status, name: toShortDateString(props.obj.closeDate ?? props.obj.announceDate) })
                            if (props.obj.acquisitionType != 'acquisition')
                                tags.push({ name: props.obj.acquisitionType.replace('_of', '').replace('_', ' ') })
                            tags.push({ name: props.obj.financiallySponsored ? "Financially Sponsored" : "Strategic" })
                            if (props.obj.exchangeTerms)
                                tags.push({ name: props.obj.exchangeTerms })
                            if (balanceSheet.value) {
                                var bsTag = {
                                    name: 'Balance Sheet', subTags: [
                                        { name: '$', title: 'in dollars' },
                                        { label: 'price' + (balanceSheet.value.pricePaidUSD == balanceSheet.value.enterpriseValueUSD ? ' & EV' : ''), name: formatMillions(balanceSheet.value.pricePaidUSD) }
                                    ]
                                }
                                if (balanceSheet.value.pricePaidUSD != balanceSheet.value.enterpriseValueUSD)
                                    bsTag.subTags.push({ label: 'EV', name: formatMillions(balanceSheet.value.enterpriseValueUSD) })
                                if (sellerFinance.value) {
                                    if (sellerFinance.value.finance.revenueUSD > 0) {
                                        bsTag.subTags.push({ label: 'rev', name: formatMillions(sellerFinance.value.finance.revenueUSD) })
                                    }
                                    if (sellerFinance.value.finance.ebitdaUSD > 0) {
                                        bsTag.subTags.push({ label: 'Ebitda', name: formatMillions(sellerFinance.value.finance.ebitdaUSD) })
                                    }
                                    if (sellerFinance.value.revenueMultiple > 0) {
                                        bsTag.subTags.push({ label: 'EV/Rev', name: sellerFinance.value.revenueMultiple.toFixed(2).toLocaleString("en-US") })
                                    }
                                    if (sellerFinance.value.ebitdaMultiple > 0) {
                                        bsTag.subTags.push({ label: 'EV/Ebitda', name: sellerFinance.value.ebitdaMultiple.toFixed(2).toLocaleString("en-US") })
                                    }
                                }
                                snapshots.push(bsTag)
                            }
                            break
                        case 'person':
                            if (primaryEmail.value)
                                tags.push({
                                    name: primaryEmail.value, //decorateHighlights({ body: primaryEmail.value, tokens: highlightText }),
                                    href: 'mailto://' + primaryEmail.value
                                })
                            if (primaryTel.value)
                                tags.push({
                                    name: props.obj.primaryTel.fullTel, //decorateHighlights({ body: props.obj.primaryTel.fullTel, tokens: highlightText }),
                                    href: 'tel://' + primaryTel.value
                                })
                            if (props.obj.titles) {
                                var titleTags = props.obj.titles.map((owner) => {
                                    return {
                                        name: owner.title,
                                        label: owner.companyName,
                                        href: '/Company/details/' + owner.companyID,
                                        icon: owner.active ? '' : 'user-slash',
                                        class: owner.active ? '' : 'tag-deleted'
                                    }
                                })
                                if (titleTags.length == 1)
                                    tags.push(titleTags[0])
                                else if (titleTags.length > 1)
                                    snapshots.push({ name: 'Companies', subTags: titleTags })
                            }
                            break;
                        case 'query': 
                           /* tags.push({
                                label: 'Results',
                                name: props.obj.total,
                            })*/

                            if (props.obj.userID)
                            tags.push({
                                label: 'UserID',
                                name: props.obj.userID,
                            })
                            break;
                        case 'cbquery':
                            if (props.obj.entryUserName)
                                tags.push({
                                    label: 'Entered by',
                                    name: props.obj.entryUserName,
                                })
                            break;
                        case 'list':
                            tags.push({
                                label: "",
                                name: props.obj.listType
                            })

                            tags.push({
                                label: 'Vertical',
                                name: props.obj.verticalID
                            })

                            tags.push({
                                label: 'Map',
                                name: props.obj.mapID,
                            })
         
                            if (props.obj.autoPopulate)
                                tags.push({
                                    label: "'",
                                    name: 'List AutoPopulates',
                                })

                            if (props.obj.totalCount)
                                tags.push({
                                    label: 'Total Count',
                                    name: props.obj.totalCount,
                                })


                            if (props.obj.counts)
                                var members = { 1: 'Companies', 3: 'Acquisitions', 2: 'Persons'}
                            _.each(props.obj.counts, function (c) {
                                if (c.count) {
                                    tags.push({
                                        label: members[c.memberTypeID],
                                        name: c.count,
                                    })
                                }
                            })

                            tags.push({
                                label: 'Sync Date',
                                name: toShortDateString(props.obj.synchDate),
                            })
        
                            break;
                        case 'event':
                            if (props.obj.ownerType)
                                tags.push({
                                    label: "",
                                    name: props.obj.ownerType.charAt(0).toUpperCase() + props.obj.ownerType.slice(1),
                                })
                            break;
                        case 'websession':
                            if (props.obj.isLandingPage)
                                tags.push({
                                    label: '',
                                    name: "Landing Page",
                                })
                            if (props.obj.isBot)
                                tags.push({
                                    label: '',
                                    name: 'Bot',
                                })
                            if (props.obj.ipAddress)
                                tags.push({
                                    label: 'IP',
                                    name: props.obj.ipAddress,
                                })
                            if (props.obj.domain)
                                tags.push({
                                    label: 'Domain',
                                    name: props.obj.domain,
                                })

                            if (props.obj.userAgentOS || props.obj.userAgentBrowser)
                                tags.push({
                                    label: '',
                                    name: props.obj.userAgentOS + ' - ' + props.obj.userAgentBrowser,
                                })


                            if (props.obj.userAgentIsMobile)
                                tags.push({
                                    label: '',
                                    name: 'Mobile'
                                })


                            if (props.obj.serverURL)
                                tags.push({
                                    label: 'Visited Site',
                                    name: props.obj.serverURL,
                                })
                            if (props.obj.name)
                                tags.push({
                                    label: 'Visited Page',
                                    name: props.obj.name,
                                })


                            break;
                        case 'emaillog':
                            if (props.obj && props.obj.owners) {
                                let company = props.obj.owners.find(o => o.ownerTypeID == 1);
                                tags.push({
                                    label: '',
                                    href: '/company/details/' + company.ownerID,
                                    name: company.ownerName
                                });
                            }
                            /*if (props.obj.outbound === true)
                                tags.push({
                                    label: '',
                                    icon: 'inbox-out',
                                    name: 'Outbound'
                                })
                            if (props.obj.outbound === false)
                                tags.push({
                                    label: '',
                                    icon: 'inbox-in',
                                    name: 'Inbound'
                                })*/
                            if (props.obj.autoReply === true)
                                tags.push({
                                    label: '',
                                    name: 'Auto Reply'
                                })
                            if (props.obj.emails.length > 1 && !props.isFullInfoContext)
                                tags.push({
                                    label: 'Emails',
                                    name: props.obj.emails.length
                                })
                            break;
                        case 'comment':
                            if (props.obj && props.obj.owners) {
                                let company = props.obj.owners.find(o => o.ownerTypeID == 1);
                                if (company) tags.push({
                                    label: '',
                                    href: '/company/detailspage/' + company.ownerID,
                                    name: company.ownerName
                                });
                            }
                            break;
                        default:
                            break;
                    }
                    if (props.obj.cloudDocCount > 0) {
                        tags.push({ 'name': 'See ' + props.obj.cloudDocCount + ' files', 'href': 'https://cmdm.berkerynoyes.com/CloudFile/?id=' + objectID.value + '&objectType=' + objectType.value + '&name=' + encodeURIComponent(objectName.value), 'icon': 'cloud-upload' })
                    }                   
                }
                return tags.concat(snapshots)
            })

            const handleClick = (tagName, action, subTag) => {
                console.log('handleClick', tagName, action, subTag)
            }
            return {
                // computed
                objectType, objectID, objectName, storeKey, tagData, primaryEmail, primaryTel, sellerFinance, balanceSheet, lastFinancial, comments,
                // methods
                toShortDateString, updateUserName, formatMillions, toEventDate, handleClick
            }
        }
    }
</script>

<style lang="scss">
    .tag-default{
        margin-right: 3px;
    }
    .tag-deleted{
        color: red
    }
</style>