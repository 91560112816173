import axios from 'axios'
import _ from 'lodash'


const state = () => ({
    emailLog: {},
    tagFieldMap: { 'hashtag': 'name', 'attag': 'value' },
    emailTypes: [
        { entry: 'Meeting Creation', id: 'calendar-ev', outbound: null, icon: 'calendar-create' },
        { entry: 'Meeting Cancellation', id: 'Cancelled ', outbound: null, icon: 'calendar-cancel' },
        { entry: 'Meeting Acceptance', id: 'Accepted: ', outbound: null, icon: 'calendar-accept' },
        { entry: 'Inbound Email', id: '', outbound: false, icon: 'inbound-email' },
        { entry: 'Outbound Email', id: '', outbound: true, icon: 'outbound-email' },
    ]
})

// getters
const getters = {
    emailLog(state) { return state.emailLog },
    emailTypes(state) { return state.emailTypes }
}

// actions
const actions = {
    fetchEmails({ commit }, opt) {
        return new Promise((resolve, reject) => {
            //we need either an ownerKey or an objectKey 
            if (!opt || (!opt.ownerKey && !(opt.obj && opt.obj.objectKey))) {
                reject({ fatal: false, message: 'Bad Email fetch Options' })
                return
            }

            if (!opt.ownerKey)
                opt.ownerKey = opt.obj.objectKey

            var emailLogState = { 'total': 0, 'events': [], 'ownerKey': opt.ownerKey, 'preparedEvents': [], 'groupedEvents': [], 'sessionEvents': [], 'fetched': false, 'exTime': 0.0 }
            var numKey = this.splitKey(opt.ownerKey)
            if (numKey.objectType == 'emaillog') {
                emailLogState.fetched = true
                if (opt.obj.emails && opt.obj.emails.length) {
                    _.each(opt.obj.emails, function (email) {
                        var ev = _.cloneDeep(opt.obj);
                        ev.owners = email.owners ? email.owners : [];
                        ev.entryUserID = email.entryUserID;
                        ev.entryUserName = email.entryUserName;
                        ev.outbound = email.outbound;
                        ev.to = email.to;
                        ev.from = email.from;
                        ev.date = email.date;

                        emailLogState.events.push(ev);
                    });
                }
                else
                    emailLogState.events.push(opt.obj)

                emailLogState.events = _.sortBy(emailLogState.events, ['date']).reverse()
                emailLogState.preparedEvents = this.prepareEvents({ 'events': emailLogState.events, 'eventSource': 'emails', 'fetchedEvents': true, 'obj': opt.obj })
                emailLogState.groupedEvents = emailLogState.events

                emailLogState.total = emailLogState.events.length;

                commit('SET_EMAILLOG', emailLogState)
                resolve({ 'fetched': true, 'total': emailLogState.total })
                return
            }
            else {
                var url = "Correspondence/EmailCommunications/?ownerID=" + numKey.id + "&ownerTypeID=" + numKey.objectTypeID + "&search=true" + (opt.limit ? "&limit=" + opt.limit : "");
                console.log('fetching emails for ' + opt.ownerKey + "...")
                axios.get(url)
                    .then(r => r.data)
                    .then(results => {
                        console.log('fetched emails for ' + opt.ownerKey)
                        emailLogState.fetched = true
                        if (results && results.resultsSet) {
                            emailLogState.exTime = results.resultsSet.exTimes ? results.resultsSet.exTimes.total : -1
                            emailLogState.total = results.resultsSet.total
                            emailLogState.events = [];
                            if (results.resultsSet.hits) {
                                results.resultsSet.hits.forEach((hit) => {
                                    var event = JSON.parse(hit.json);
                                    if (event.emails && event.emails.length) {
                                        _.each(event.emails, function (email) {
                                            var ev = _.cloneDeep(event);
                                            ev.owners = email.owners ? email.owners : [];
                                            ev.entryUserID = email.entryUserID;
                                            ev.entryUserName = email.entryUserName;
                                            ev.outbound = email.outbound;
                                            ev.to = email.to;
                                            ev.from = email.from;
                                            ev.date = email.date;

                                            emailLogState.events.push(ev);
                                        });
                                    }
                                    else
                                        emailLogState.events.push(event)
                                })
                            }
                            emailLogState.events = _.sortBy(emailLogState.events, ['date']).reverse()
                            emailLogState.preparedEvents = this.prepareEvents({ 'events': emailLogState.events, 'eventSource': 'emails', 'fetchedEvents': true, 'obj': opt.obj })
                            emailLogState.groupedEvents = emailLogState.events

                            commit('SET_EMAILLOG', emailLogState)
                            resolve({ 'fetched': true, 'total': emailLogState.total })
                            return
                        }
                        else {
                            commit('SET_EMAILLOG', emailLogState)
                            reject({ fatal: true, message: "emails not found. id: " + opt.ownerKey })
                        }

                    }).catch(function (error) {
                        emailLogState.fetched = true
                        commit('SET_EMAILLOG', emailLogState)
                        reject({ fatal: true, message: error })
                    })
            }
        })
    },
}

// mutations
const mutations = {
    SET_EMAILLOG(state, opt) {
        state.emailLog[this.encodeOwnerKey(opt.ownerKey)] = opt
    },
    UNSET_EMAILLOG(state, opt) {
        delete state.emailLog[this.encodeOwnerKey(opt.ownerKey)]
    },
}



export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
